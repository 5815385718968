/* tslint:disable:ordered-imports */
import Logger from './utils/Logger'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Theme } from '@120wateraudit/envirio-components'
import { ApolloProvider } from 'react-apollo'
import { Auth0Provider } from '@auth0/auth0-react'
import ReactGA from 'react-ga4'
import { AUTH0_DOMAIN, AUTH0_CLIENT_ID } from 'src/constants'
// Vendor Styles
import 'noty/lib/noty.css'
import 'rc-calendar/assets/index.css'
import 'rc-time-picker/assets/index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-select/dist/react-select.css'
import 'semantic-ui-css/semantic.min.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import '@120wateraudit/envirio-components/src/styles.css'
import './global.css'

import Routes from './router/index'
import { store } from './store'
import { schoolsClient } from './apollo-clients'
import { MobileApp } from 'src/mobile-app'

/* tslint:enable:ordered-imports */

const main = () => {
  Logger.init()
  renderApp()
}

const rootDiv = document.getElementById('root')

interface InfoCache {
  currentExperience: Experience
  appLoaded: boolean
}

enum Experience {
  Mobile = 'Mobile',
  Desktop = 'Desktop'
}

const cache: InfoCache = {
  currentExperience: Experience.Desktop,
  appLoaded: false
}

import { browserHistory } from 'src/utils/navigation'

const renderApp = () => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '')
  }

  if (window.innerWidth <= 600) {
    if (cache.currentExperience !== Experience.Mobile || !cache.appLoaded) {
      ReactDOM.unmountComponentAtNode(rootDiv as HTMLElement)
      ReactDOM.render(
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          onRedirectCallback={appState =>
            browserHistory.replace(
              (appState && appState.returnTo) || window.location.pathname
            )
          }
          authorizationParams={{
            redirect_uri: window.location.origin
          }}
          useRefreshTokens={true}
          useRefreshTokensFallback={true}
          cacheLocation={'memory'}
        >
          <Provider store={store}>
            <Theme>
              <>
                <MobileApp />
              </>
            </Theme>
          </Provider>
        </Auth0Provider>,
        rootDiv
      )
      cache.currentExperience = Experience.Mobile
    }
  } else {
    if (cache.currentExperience !== Experience.Desktop || !cache.appLoaded) {
      ReactDOM.unmountComponentAtNode(rootDiv as HTMLElement)
      ReactDOM.render(
        <Auth0Provider
          domain={AUTH0_DOMAIN}
          clientId={AUTH0_CLIENT_ID}
          onRedirectCallback={appState =>
            browserHistory.replace(
              (appState && appState.returnTo) || window.location.pathname
            )
          }
          authorizationParams={{
            redirect_uri: window.location.origin
          }}
          useRefreshTokens={true}
          useRefreshTokensFallback={true}
          cacheLocation={'memory'}
        >
          <Provider store={store}>
            <ApolloProvider client={schoolsClient}>
              <Theme>
                <>
                  <Routes />
                </>
              </Theme>
            </ApolloProvider>
          </Provider>
        </Auth0Provider>,
        rootDiv
      )
      cache.currentExperience = Experience.Desktop
    }
  }
}

main()
